import React, { useEffect, useState, useRef, forwardRef } from 'react';
import { useResizeDetector } from 'react-resize-detector';
import AvatarEditor from 'react-avatar-editor';
import Dropzone from 'react-dropzone';
import SignatureCanvas from 'react-signature-canvas';
import 'bootstrap/dist/css/bootstrap.css';
import './App.css';

import cardUsedToBeYoungSquare from './images/usedtobeyoungsquare.jpg';
import cardUsedToBeYoungPortrait from './images/usedtobeyoungportrait.jpg';
import cardUsedToBeYoungSquareHalf from './images/squarehalf.jpg';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUndo, faRedo, faSearchMinus, faSearchPlus, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';

const App = () => {	
	const { ref: idContainerRef } = useResizeDetector();
  const nameInputRef = useRef<any>();
  const photoRef = useRef<any>();

  const [page, setPage] = useState('form');
  const [modal, setModal] = useState<string>('');
  const [cardWidthSquare, setcardWidthSquare] = useState<number>();
  const [cardHeightSquare, setcardHeightSquare] = useState<number>();
  const [cardWidthPortrait, setcardWidthPortrait] = useState<number>();
  const [cardHeightPortrait, setcardHeightPortrait] = useState<number>();
  const [bgImage, setBGImage] = useState<string>(cardUsedToBeYoungSquare);
  const [bgImagePortrait, setBGImagePortrait] = useState<string>(cardUsedToBeYoungPortrait);
  const [fullName, setFullName] = useState<string>('');
  const [photoImageData, setPhotoImageData] = useState<string>('');
  const [cardImageDataSquare, setCardImageDataSquare] = useState<string>();
  const [cardImageDataPortrait, setCardImageDataPortrait] = useState<string>();
	const [show, setShow] = useState(false);
	const usedToBeOptions = ['\nused \nto be\ncrazy', '\nused\nto be\nwild', '\nused\nto be\nyoung'];
	const [usedToBeText, setUsedToBeText] = useState<string>('');
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
	
  useEffect(() => {
    // generate card when the user submits
    if (page === 'result') {
      (async () => {
        const cardImageDataSquare = await generateCardSquare(photoImageData, fullName, usedToBeText);
        setCardImageDataSquare(cardImageDataSquare);
        const cardImageDataPortrait = await generateCardPortrait(photoImageData, fullName, usedToBeText);
        setCardImageDataPortrait(cardImageDataPortrait);
        setUsedToBeText(usedToBeOptions[Math.floor(Math.random() * usedToBeOptions.length)]);
      })();
    }
  }, [page, photoImageData, fullName, usedToBeText]);

  return (
    <>
      <div className="start-0 top-0 w-100 d-flex flex-column">
        <div className="d-flex flex-column flex-fill">
          {page === 'form' && (
            <>
              <div className="position-relative">
              	<input ref={nameInputRef} type="text" id="form-control-full-name" placeholder="First Name:" autoComplete="off" value={fullName} onChange={event => setFullName(event.target.value)} onKeyDown={e => e.key === 'Enter'} />
                <div className="cursor-pointer" onClick={() => setModal('photo')}>
                  {photoImageData ? (
                    <img src={photoImageData} className="imageuploaded" alt="When You Used To Be Young" />
                  ) : (
                    <button className="imageupload">Upload Image</button>
                  )}
                </div>
              </div>
              <button className="text-uppercase letter-spaced submit-button" onClick={() => { setPage('result'); }}>
                <strong>Submit</strong>
              </button>
            </>
          )}

          {page === 'result' && (
            <>
              <div ref={idContainerRef} className="flex-fill align-items-center justify-content-center cardcontainer d-flex">
                {cardImageDataSquare && (
                  <img src={cardImageDataSquare} style={{ width: cardWidthSquare, height: cardHeightSquare }} alt="Your Card" />
                )}
                {cardImageDataPortrait && (
                  <img src={cardImageDataPortrait} style={{ width: cardWidthPortrait, height: cardHeightPortrait }} alt="Your Card" />
                )}
              </div>
              <div className="d-flex align-items-center justify-content-center pb-1">
                <div>
                  <button className="btn mx-1 text-uppercase letter-spaced result-button" style={{ width: 150, height: 56 }} onClick={() => setPage('form')}>
                    <strong>Back</strong>
                  </button>
                  <div className="split-rows">&nbsp;</div>
                  <a download="usedtobeyoung.png" href={cardImageDataSquare} target="_blank" className="btn mx-1 text-uppercase letter-spaced result-button mobile-buttons" style={{ width: 150, height: 56 }}>
                    <strong>Download Square</strong>
                  </a>
                  <a download="usedtobeyoung.png" href={cardImageDataPortrait} target="_blank" className="btn mx-1 text-uppercase letter-spaced result-button mobile-buttons" style={{ width: 150, height: 56 }}>
                    <strong>Download Portrait</strong>
                  </a>
                  <a download="usedtobeyoung.png" href={cardImageDataSquare} target="_blank" className="btn mx-1 text-uppercase letter-spaced result-button desktop-buttons" style={{ width: 150, height: 56 }}>
                    <strong>Download Square</strong>
                  </a>
                  <a download="usedtobeyoung.png" href={cardImageDataPortrait} target="_blank" className="btn mx-1 text-uppercase letter-spaced result-button desktop-buttons" style={{ width: 150, height: 56 }}>
                    <strong>Download Portrait</strong>
                  </a>
                  <div className="split-rows">&nbsp;</div>
                  <a href="https://www.facebook.com/sharer/sharer.php?u=https://www.mileycyrususedtobeyoung.com" target="_blank" className="btn mx-1 text-uppercase letter-spaced result-button" style={{ width: 150, height: 56 }}><strong>Share On Facebook</strong></a>
                  <a href="https://twitter.com/intent/tweet?url=https%3A%2F%2Fwww.mileycyrususedtobeyoung.com%2F&hashtags=%23UsedToBeYoung" target="_blank" className="btn mx-1 text-uppercase letter-spaced result-button" style={{ width: 150, height: 56 }}><strong>Share On Twitter</strong></a>
                  {/* <button className="btn btn-primary mx-1 text-uppercase letter-spaced" style={{ width: 150, background: '#4963FF', color: '#ffffff' }}>Share</button> */}
                </div>
              </div>
            </>
          )}
        </div>
      </div>

      {modal && (
        <div className="position-fixed top-0 start-0 w-100 h-100 d-flex align-items-center justify-content-center">
          <div id="top-layer" className="position-absolute start-0 top-0 w-100 h-100 bg-black" style={{ opacity: 0.5 }} onClick={() => { photoRef.current && setPhotoImageData(photoRef.current.getImage().toDataURL()); setModal(''); }}></div>
          <div className="position-relative p-3" style={{ width: 320 }}>

            {modal === 'photo' && (
              <>
                <PhotoSelector ref={photoRef} />
                <button className="btn btn-light border okay-btn" onClick={() => {setModal('');}}>
								  <FontAwesomeIcon icon={faTimesCircle} />
								</button>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
}

const PhotoSelector = forwardRef((props: any, ref: any) => {
  const [width, setWidth] = useState(220);
  const [height, setHeight] = useState(220);
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [image, setImage] = useState('');
	
  return (image === '' ? (
    <Dropzone
      accept="image/*"
      onDrop={(dropped: any) => setImage(dropped[0])}
      disabled={image !== ''}
      maxFiles={1}
    >
      {({ getRootProps, getInputProps }) => (
        <div {...getRootProps()}>
          <input {...getInputProps()} />
          <div className="d-flex align-items-center justify-content-center cursor-pointer" style={{ height: 316, borderStyle: 'solid', borderWidth: '1px', borderColor: 'rgba(0, 0, 0, 1)', background: '#ffffff', color: '#000000' }}>
            <strong>Drop or Upload</strong>
          </div>
        </div>
      )}
    </Dropzone>
  ) : (
    <div className="position-relative">
      <AvatarEditor
        ref={ref}
        width={width}
        height={height}
        scale={scale}
        rotate={rotate}
        image={image}
        className="rounded"
      />
			
      <div className="input-group mb-2">
        <div className="input-group-text bg-white">
          <FontAwesomeIcon icon={faSearchMinus} />
        </div>
        <div className="form-control border-start-0 border-end-0 p-0 pt-1">
          <input type="range" className="form-range" value={scale} min={1} max={3} step={0.01} onChange={event => setScale(parseFloat(event.target.value))} />
        </div>
        <div className="input-group-text bg-white">
          <FontAwesomeIcon icon={faSearchPlus} />
        </div>
      </div>
      <div className="btn-group w-100">
        <button className="btn btn-light border" onClick={() => { setRotate(rotate + 90); setWidth(height); setHeight(width); }}>
          <FontAwesomeIcon icon={faRedo} />
        </button>
        <button className="btn btn-light border" onClick={() => { setRotate(rotate - 90); setWidth(height); setHeight(width); }}>
          <FontAwesomeIcon icon={faUndo} />
        </button>
        <button className="btn btn-light border" onClick={() => { window.document.getElementById('top-layer')!.click(); }}>
				  <FontAwesomeIcon icon={faCheckCircle} />
				</button>
      </div>
    </div>
  ));
});

const generateCardSquare = async (photoImageData: string, fullName: string, usedToBeText: string) => {

	const cardBGSquare = await AsyncImage(cardUsedToBeYoungSquare);
	const cardBGSquareHalf = await AsyncImage(cardUsedToBeYoungSquareHalf);

  let canvas = document.createElement('canvas');
  let canvasContext = canvas.getContext('2d');
  canvas.width = 1500;
  canvas.height = 1500;
  canvasContext!.strokeStyle = 'black';
  canvasContext!.lineWidth = 6;

	function drawRotated(degrees:any) {
	  canvasContext!.rotate(degrees*Math.PI/180);
	  canvasContext!.restore();
	}
  
  // Template	
	var templateDegrees=0;
	
	canvasContext!.drawImage(cardBGSquare, 0, 0);
  canvasContext!.globalCompositeOperation = 'source-over';
  
  // Photo
  if (photoImageData) {
	  const photoImage = await AsyncImage(photoImageData);
	  
	  // Rotate Photo
    canvasContext!.drawImage(photoImage, -375, 0, 1500, 1500);
    
    canvasContext!.drawImage(cardBGSquareHalf, 750, 0);
    
    canvasContext!.shadowBlur = 0;
		canvasContext!.shadowOffsetX = 0;
		canvasContext!.shadowOffsetY = 0;
  }
  
  canvasContext!.globalCompositeOperation = 'source-over';
  
  canvasContext!.fillStyle = "#ffffff";
  
  // Name
	var nameDegrees=0;
  
  drawRotated(nameDegrees);
  
	var ctx = canvasContext!;
	var fullNameText = fullName + usedToBeText;
  canvasContext!.font = '200px compacta_bd_btbold';
  var nameTextWidth = ctx.measureText(fullNameText).width;
  
  var lines = fullNameText.toUpperCase().split('\n');
	var x = 790;
	var y = 200;
	var lineheight = 200;

	for (var i = 0; i<lines.length; i++)
    canvasContext!.fillText(lines[i], x, y + (i*lineheight) );
  
  canvasContext!.globalCompositeOperation = 'source-over';
  
  canvasContext!.globalCompositeOperation = 'source-over';
  //canvasContext!.globalCompositeOperation = 'destination-over';

  return canvas.toDataURL();

}

const generateCardPortrait = async (photoImageData: string, fullName: string, usedToBeText: string) => {

	const cardBGPortrait = await AsyncImage(cardUsedToBeYoungPortrait);

  let canvas = document.createElement('canvas');
  let canvasContext = canvas.getContext('2d');
  canvas.width = 1080;
  canvas.height = 1920;
  canvasContext!.strokeStyle = 'black';
  canvasContext!.lineWidth = 6;

	function drawRotated(degrees:any) {
	  canvasContext!.rotate(degrees*Math.PI/180);
	  canvasContext!.restore();
	}
  
  // Template	
	var templateDegrees=0;
	
	canvasContext!.drawImage(cardBGPortrait, 0, 0);
	
  canvasContext!.globalCompositeOperation = 'source-over';
  
  // Photo
  if (photoImageData) {
	  const photoImage = await AsyncImage(photoImageData);
	  
	  // Rotate Photo
    canvasContext!.drawImage(photoImage, 0, 0, 1080, 1080);
    
    canvasContext!.shadowBlur = 0;
		canvasContext!.shadowOffsetX = 0;
		canvasContext!.shadowOffsetY = 0;
  }
  
  canvasContext!.globalCompositeOperation = 'source-over';
  
  canvasContext!.fillStyle = "#ffffff";
  
  // Name
	var nameDegrees=0;
  
  drawRotated(nameDegrees);
  
	var ctx = canvasContext!;
	var fullNameText = fullName + usedToBeText;
  canvasContext!.font = '180px compacta_bd_btbold';
  var nameTextWidth = ctx.measureText(fullNameText).width;
  
  var lines = fullNameText.toUpperCase().split('\n');
	var x = 40;
	var y = 1290;
	var lineheight = 190;

	for (var i = 0; i<lines.length; i++)
    canvasContext!.fillText(lines[i], x, y + (i*lineheight) );
  
  canvasContext!.globalCompositeOperation = 'source-over';
  
  canvasContext!.globalCompositeOperation = 'source-over';
  //canvasContext!.globalCompositeOperation = 'destination-over';

  return canvas.toDataURL();

}

const AsyncImage = (src: string) => {
  return new Promise<HTMLImageElement>((resolve, reject) => {
    let image = new Image();
    image.onload = () => resolve(image);
    image.onerror = reject;
    image.src = src;
  })
}

export default App;